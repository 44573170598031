.container {
	display: flex;
	flex-direction: column;
	position: relative;
}

.contentHeader {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: white;
	margin-top: 64px;
	z-index: 800;
}

.contentHeaderContainer {
	display: flex;
	background: white;
	padding: 20px 50px;
}

.filterButton {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.filterButton img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(4458%) hue-rotate(175deg) brightness(102%) contrast(95%);
}

.filterButton:hover {
	cursor: pointer;
}

.filterButtonSelected {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: #278EA5;
	display: flex;
	align-items: center;
	justify-content: center;
}

.filterButtonSelected:hover {
	cursor: pointer;
}

.filterButtonSelected img {
	filter: brightness(0) saturate(100%) invert(100%) sepia(2%) saturate(7482%) hue-rotate(322deg) brightness(106%) contrast(107%);
}

.singleChartContainer{
	display: flex;
	flex-direction: column;
	flex: 1 ;
}
.singlePieChartContainer{
	display: flex;
	flex-direction: column;
	flex: 0 ;
}

.graphContainer {
	background: #FFFFFF;
	box-shadow: 0px 2px 4px rgba(101, 113, 128, 0.16);
	border-radius: 16px;
	height: 440px;
	padding: 20px 20px;
}

.pieAndCycleGraphContainer {
	background: #FFFFFF;
	box-shadow: 0px 2px 4px rgba(101, 113, 128, 0.16);
	border-radius: 16px;
	height: 440px;
	padding: 20px 20px;
}

.content {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.timeTabContainer {
	display: flex;
	justify-content: flex-end;
}

.tabInOrderCycleAndPieContainer {
	display: flex;
}

.timeTabSelected {
	padding: 7px 12px;
	border: 1px solid #D9E0E9;
	border-radius: 100px;
	margin: 0 5px;
	background: #278EA5;
	color: #fff;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.75px;
	height: 38px;
	display: flex;
	align-items: center;
	justify-items: center;
	justify-content: center;
}

.timeTab {
	padding: 7px 12px;
	border: 1px solid #D9E0E9;
	border-radius: 100px;
	margin: 0 5px;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.75px;
	color: #6E7C91;
	height: 38px;
	display: flex;
	align-items: center;
	justify-items: center;
	justify-content: center;
}

.timeTab:hover {
	background: #278EA5;
	font-weight: bold;
	color: #fff;
	cursor: pointer;
	transition: background .2s, color .2s;
}

.tabContainer {
	display: flex;
}

.data {
	width: 100%;
	padding: 24px 38px;
}

.data h3 {
	color: black;
	font-size: 15px;
	font-weight: bold;
	margin-bottom: 24px;
}

.statContainer {
	box-shadow: 0px 2px 8px rgba(101, 113, 128, 0.24);
	border-radius: 8px;
	padding: 20px;
	flex: 1;
	display: flex;
	flex-direction: column;
}

.stat {
	flex: 1;
	padding: 20px 20px;
	display: flex;
	flex-direction: column;
}

.stat h2 {
	font-size: 16px;
	color: #4B5766;
}

.statValueContainer {
	font-size: 20px;
	color: black;
	font-weight: bold;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.percentageContainer {
	display: flex;
	align-items: center;
}

.percentagePositive {
	color: #00BA88;
	font-weight: normal;
	font-size: 16px;
	margin-left: 6px;
}

.percentageNegative {
	color: #FF5959;
	font-weight: normal;
	font-size: 16px;
	margin-left: 6px;
}

.yesterday {
	font-size: 14px;
	color: #64748B;
	font-weight: normal;
	margin-left: 6px;
}

.dash {
	height: 2px;
	width: 7px;
	background-color: #278ea5;
}

.label {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: 0.25px;
	color: #6e7c91;
	width: 103px;
}

.section {
	margin-bottom: 40px;
}

.radioColumn {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.empty {
	margin-top: 220px;
}