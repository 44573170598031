.page {
	height: 100%;
	width: 100%;
	display: flex;
    flex-direction: column;
    margin-top: 80px;
}

.filters {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}

.cards {
    padding-bottom: 40px;
}

.cardTitle {
    font-weight: normal;
}

.cardValue {
    font-size: 16px;
    font-weight: 600;
}

.header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.title {
    margin: 0;
    font-weight: bold;
    font-size: 18px;
}

.selectContainer {
    width: 250px;
}

.section {
    margin-bottom: 40px;
    width: 100%;
    border-radius: 8px;
    width: 100%;
    padding: 24px;
    box-shadow: 0px 1px 4px rgba(101, 113, 128, 0.16);
}

.pieChart {
    height: 420px;
}

.barChart {
    height: 500px;
    width: 100%;
}

.spaceBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.optionsContainer {
    padding: 10px 16px;	
}

.options {
    max-height: 280px;
    overflow-y: auto;
}

.barChartContainer {
    display: flex;
    align-items: center;
}

.dash {
	height: 2px;
	width: 7px;
	background-color: #278ea5;
}